import { currentUser, isAuthActive } from '@/constants/config'
import Axios from 'axios'
import Util from '@/utils/axios-util.js'
export default {
  state: {
    currentUser: isAuthActive && localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
    loginError: null,
    processing: false,
    forgotMailSuccess: null,
    resetPasswordSuccess: null,
    isAuthenticated: false,
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
    forgotMailSuccess: state => state.forgotMailSuccess,
    resetPasswordSuccess: state => state.resetPasswordSuccess,
    isAuthenticated: state => state.isAuthenticated,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
      state.processing = false
      state.loginError = null
      state.isAuthenticated = true
    },
    setLogout(state) {
      state.currentUser = null
      state.processing = false
      state.loginError = null
      state.isAuthenticated = false
    },
    setProcessing(state, payload) {
      state.processing = payload
      state.loginError = null
    },
    setError(state, payload) {
      state.loginError = payload
      state.currentUser = null
      state.processing = false
      state.isAuthenticated = false
    },
    setForgotMailSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.forgotMailSuccess = true
    },
    setResetPasswordSuccess(state) {
      state.loginError = null
      state.currentUser = null
      state.processing = false
      state.resetPasswordSuccess = true
    },
    clearError(state) {
      state.loginError = null
    },
  },
  actions: {
    login({ commit }, payload) {
      commit('clearError')
      commit('setProcessing', true)

      return new Promise((resolve, reject) => {
        Axios.post('/home/login', {
          UserName: payload.email,
          Password: payload.password,
          //TenantID: 1,
        })
          .then(function(response) {
            if (response.data.succeeded) {
              let roles =
                response.data.data.tenantUserRolesDDL && response.data.data.tenantUserRolesDDL.length
                  ? response.data.data.tenantUserRolesDDL
                  : []
              let item = Object.assign(response.data.data, {
                token: response.data.data.token,
                title: response.data.data.name,
                accessibleComponentCodes: response.data.data.accessibleComponentCodes,
                id: response.data.data.id,
                roleId: response.data.data.roleId,
                roleCode: response.data.data.roleCode,
                img: currentUser.img,
                date: currentUser.date,
                roles: roles,
                isPasswordExpired: response.data.data.isPasswordExpired,
              })
              commit('setUser', item)
              resolve(response)
            } else {

              commit('setError', response.data.errors[0].title)
              setTimeout(() => {
                commit('clearError')
              }, 3000)
              reject(response)
            }
          })
          .catch(function(response) {
            commit('setError', response.message)
            setTimeout(() => {
              commit('clearError')
            }, 3000)
            reject(response)
          })
      })
    },
    switchRole({ commit }, payload) {
      let e = this
      commit('clearError')
      commit('setProcessing', true)
      return new Promise((resolve, reject) => {
        Util.Axios.post(`/home/switch-to-role/${payload.roleCode}`)
          .then(function(response) {
            let item = Object.assign(
              {},
              {
                token: response.data.data.token,
                title: response.data.data.name,
                id: response.data.data.id,
                roleId: response.data.data.roleId,
                roleCode: response.data.data.roleCode,
                accessibleComponentCodes: response.data.data.accessibleComponentCodes,
                img: e.state.user.currentUser.img,
                date: e.state.user.currentUser.date,
                roles: e.state.user.currentUser.roles,
                isPasswordExpired: response.data.data.isPasswordExpired,
              },
            )
            commit('setUser', item)
            resolve(response)
            commit('clearError')
          })
          .catch(function(error) {})
          .finally(function() {
            commit('setProcessing', false)
          })
      })
    },
    externalLogin({ commit }, payload) {
      let e = this
      commit('clearError')
      commit('setProcessing', true)

      return new Promise((resolve, reject) => {
        Axios.post('/home/external-login', payload)
          .then(function(response) {
            if (response.data.succeeded) {
              let roles =
                response.data.data.tenantUserRolesDDL != null && response.data.data.tenantUserRolesDDL != undefined
                  ? response.data.data.tenantUserRolesDDL
                  : []
              let item = Object.assign(
                {},
                {
                  token: response.data.data.token,
                  title: response.data.data.name,
                  id: response.data.data.id,
                  accessibleComponentCodes: response.data.data.accessibleComponentCodes,
                  roleId: response.data.data.roleId,
                  roleCode: response.data.data.roleCode,
                  img: '',
                  date: '',
                  roles: roles,
                },
              )
              commit('setUser', item)
              resolve(response)
            } else {
              commit('setError', response.data.errors[0].title)
              setTimeout(() => {
                commit('clearError')
              }, 3000)
              reject(response)
            }
          })
          .catch(function(response) {
            commit('setError', response.message)
            setTimeout(() => {
              commit('clearError')
            }, 3000)
            reject(response)
          })
      })
    },
    signOut({ commit }) {
      return new Promise((resolve, reject) => {
        Axios.get('/home/logout')
          .then(function(response) {
            if (response.data.succeeded) {
              Util.Axios.defaults.headers.common['Authorization'] = ''
              commit('setLogout')
              resolve()
            } else {
              commit('setError', response.data.errors[0].title)
              setTimeout(() => {
                commit('clearError')
              }, 3000)
              reject(response)
            }
          })
          .catch(function(response) {
            commit('setError', response.message)
            setTimeout(() => {
              commit('clearError')
            }, 3000)
            reject(response)
          })
      })
    },
    clearSession({ commit }) {
      return new Promise((resolve, reject) => {
        Util.Axios.defaults.headers.common['Authorization'] = ''
        commit('setLogout')
        resolve()
      })
    },

    // forgotPassword({ commit }, payload) {
    //   commit('clearError')
    //   commit('setProcessing', true)
    //   firebase
    //     .auth()
    //     .sendPasswordResetEmail(payload.email)
    //     .then(
    //       user => {
    //         commit('clearError')
    //         commit('setForgotMailSuccess')
    //       },
    //       err => {
    //         commit('setError', err.message)
    //         setTimeout(() => {
    //           commit('clearError')
    //         }, 3000)
    //       }
    //     )
    // },
    //      resetPassword({ commit }, payload) {
    //       let e=this;
    //       console.log(e.$route);
    //        commit('clearError')
    //        commit('setProcessing', true)
    //       //  firebase
    //       //    .auth()
    //       //    .confirmPasswordReset(payload.resetPasswordCode,payload.newPassword)
    //       //    .then(
    //       //      user => {
    //       //        commit('clearError')
    //       //        commit('setResetPasswordSuccess')
    //       //      },
    //       //      err => {
    //       //        commit('setError', err.message)
    //       //        setTimeout(() => {
    //       //          commit('clearError')
    //       //        }, 3000)
    //       //      }
    //       //    )
    // console.table({
    //   password: payload.password,
    //             confirmPasswordReset: payload.confirmPasswordReset,
    //             // code: e.$route.query.c,
    //             // applicationUserId:e.$route.query.i,
    // })
    //          return new Promise((resolve, reject) => {
    //           Axios.post("/home/set-password", {
    //             password: payload.password,
    //             confirmPasswordReset: payload.confirmPasswordReset,
    //             code: e.$route.query.c,
    //             applicationUserId:e.$route.query.i,
    //           })
    //             .then(function(response) {
    //               if (response.data.succeeded) {
    //                 commit("setResetPasswordSuccess");
    //                 resolve(response);
    //               } else {
    //                 commit("setError", response.data.errors[0].title);
    //                 setTimeout(() => {
    //                   commit("clearError");
    //                 }, 3000);
    //                 reject(response);
    //               }
    //             })
    //             .catch(function(response) {
    //               commit("setError", response.message);
    //               setTimeout(() => {
    //                 commit("clearError");
    //               }, 3000);
    //               reject(response);
    //             });
    //         });
    //      },
    /*
        return await auth.(resetPasswordCode, newPassword)
         .then(user => user)
         .catch(error => error);
     */
  },
}
