import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import mixin from '@/utils/mixins.js'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import ToastProps from '@/utils/toast-props.js'
import Vuelidate from 'vuelidate'
import { validationMixin } from 'vuelidate'
import Util from '@/utils/axios-util.js'
import { config } from '@/constants/config.js'
import moment from 'moment'
import JsonExcel from 'vue-json-excel'

Vue.config.productionTip = false

Vue.mixin(mixin)
Vue.mixin(validationMixin)
Vue.use(Vuelidate)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

Vue.filter('format_date', function(value) {
  if (!value) return ''
  return moment(value).format(config.dateFormat)
})

//Example value="2021-10-11T05:33:56"
Vue.filter('format_date_local_timezone', function(value) {
  if (!value) return ''
  var tempDate = new Date(value)
  tempDate.setMinutes(tempDate.getMinutes() - tempDate.getTimezoneOffset())
  return moment(tempDate).format(config.dateFormat)
})

//Example value="2021-10-11T05:33:56.010359Z"
Vue.filter('format_utc_date_local_timezone', function(value) {
  if (!value) return ''
  var tempDate = new Date(value)
  return moment(tempDate).format(config.dateFormat)
})

Vue.filter('format_money', function(value, currency) {
  if (!value) return ''
  let dollarIndianLocale = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: currency,
  })
  return dollarIndianLocale.format(value)
})

Vue.component('downloadExcel', JsonExcel)

// below code is to add token in axios on page refresh
if (store.state.user.currentUser != null) {
  var token = store.state.user.currentUser.token
  Util.Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
} else {
  Util.Axios.defaults.headers.common['Authorization'] = ''
}
Vue.use(Toast, ToastProps)
